import React from 'react'
import loadable from '@loadable/component'
import { SuspenseSSR } from '@vtex/store-ui'
import Layout from 'src/components/Layout'

const LoginView = loadable(() => import('src/views/login'))

const LoginPage = () => {
  return (
    <Layout>
      <SuspenseSSR fallback={null}>
        <LoginView />
      </SuspenseSSR>
    </Layout>
  )
}

export default LoginPage
